import React from 'react';
import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Content } from '../components/Content';

export default () => (
  <Layout>
    <Meta title="Kontakt" />
    <Main>
      <h1>Kontakt</h1>
      <Content>
        <p>
          Finlux kuchynské štúdio
        </p>
        <p>
          Mobil: 0905 603 567
          <br />
          E-mail: <a href="mailto:finlux@finlux.sk">finlux@finlux.sk</a>
        </p>
        <p>
          IČO: 17242363
          <br />
          DIČ: 1030239617
        </p>
      </Content>
    </Main>
  </Layout>
);
